@media screen and (min-width: 358px) {

  .FormContact {

    margin-top: 6rem;
  }

  .FormContact_contentInfos_box {

    padding: .70rem;
  }

  .FormContact_contentInfos_title p {

    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
  }

  .FormContact_contentInfos_title h1 {

    color: orangered;

    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
  }

  .FormContact_contentInfos_phone p {

    font-size: 1rem;
    margin: .50rem 0 0 0;
  }

  .FormContact_form {

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
  }

  .FormContact_subject {

    width: 100%;
    font-size: 1.1rem;
  }

  .FormContact_subject::placeholder {

    font-size: 1.1rem;
    font-family: sans-serif;
    padding-left: .10rem;
    box-sizing: border-box;
  }

  .FormContact_form input {

    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    margin-bottom: 1rem;
    box-sizing: border-box;
    font-size: 1.1rem;
  }

  .FormContact_form input::placeholder {

    font-size: 1.1rem;

  }

  .FormContact_form textarea {

    font-size: 1.1rem;

    font-family: sans-serif;
    box-sizing: border-box;
    padding: .5rem;
  }

  .FormContact_form textarea::placeholder {

    font-size: 1.1rem;

    box-sizing: border-box;
    padding: .4rem;
  }

  .FormContact_form_submit {

    margin-top: 2rem;
  }

  .FormContact_form_submit input {

    background-color: rgb(205, 140, 18);
    color: white;

    width: 50%;

    font-size: 1.1rem;
    font-weight: bold;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all ease-in-out .3s;
  }

  .FormContact_form_submit input:hover {

    background-color: rgb(205, 140, 18);

    width: 55%;
    transition: all ease-in-out .3s;
    border-radius: 10px;
    cursor: pointer;
  }

  .FormContact_form_Errors {

    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 540px) {

  .FormContact {

    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5rem;

    
  }


  .FormContact_contentInfos_box {


    background-color: rgb(248, 247, 247);
    max-width: 544px;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 768px) {

  .FormContact {

    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;

    margin: 5rem 0 5rem 0;
  }

  .FormContact_contentInfos_box {

    padding: .70rem;
  }


}