@media screen and (min-width: 358px) {

  .MessageAuth {

    background-color: #f5020295;

    width: 320px;

    margin-top: 1rem;
    padding: .10rem;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .MessageAuth_TXT p {

    color: white;

    font-size: 1.1rem;
    text-align: center;
    font-weight: bold;
  }
}

@media screen and (min-width: 550px) {
  
  .MessageAuth  {
    width: 100%;
  }
}