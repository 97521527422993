@media screen and (min-width: 358px) {
  
  .Auth_Media {

    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}