@media screen and (min-width: 1495px) {
 
  .Ads {

    width: 100%;
    height: 100%;

    padding: 6rem 0 4rem 0;
    box-sizing: border-box;

    z-index: -100;
  }

  .Ads_cards {

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .Ads_card-content {

    width: 100%;
    height: 400px;

    position: relative;

    max-width: 260px;
  }

  .Ads_card-box {

    background-color: rgb(247, 245, 245);

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }

  .Ads_card-icon {

    position: absolute;
    top: 5px;
    right: 5px;

    display: flex;
    
  }

  .Ads_card-icon img{

    width: 35px;
  }

  .Ads_card-box p {

    margin: 0;
  }
}