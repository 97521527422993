@media screen and (min-width: 358px) {

  .Professionals {

    background-color: rgb(246, 242, 242);
  }

  .Professionals_scratched {

    background-image: url("../../../public/backgroud/rasgado.png");
    background-position: bottom;

    width: 100%;
    height: 40px;
  }

  .Professionals_content {

    padding: .70rem;
  }

  .Professionals_title {

    font-size: 1.1rem;
    font-weight: 500;
    font-family: "Anta", sans-serif;
    letter-spacing: 1px;

    margin: .30rem 0 0 0;
  }

  .Professionals_box-services h1 {

    color: rgb(205, 141, 23);

    font-size: 1.4rem;
    font-weight: 700;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 2px;

    margin: 1rem 0 0 0;
  }

  .Professionals_description {

    color: rgb(75, 74, 72);

    font-size: 1rem;
    font-family: sans-serif;
    line-height: 22px;

    margin: 1rem 0 0 0;
  }

  .Professionals_Read-more {

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 2rem;
  }

  .Professionals_Read-more a {

    background-color: red;
    color: white;

    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    transition: all ease-in-out .3s;
    font-size: 1.1rem;
    padding: 10px 50px;
  }

  .Professionals_services {

    padding: 2rem .70rem .70rem .70rem;
  }

  .Professionals_box-content-infos {

    background-color: white;

    padding: .60rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 1rem;
  }

  .Professionals_box-content-infos img {

    width: 70px;
  }

  .Professionals_box-content-infos p {

    color: rgb(18, 17, 17);

    font-weight: bold;
    text-decoration: none;
    font-size: 1.1rem;

    margin: .50rem 0 0 0;
  }
}

@media screen and (min-width: 580px) {

  .Professionals_content {

    text-align: center;
  }

  .Professionals_box-services h1 {

    margin: 1.7rem 0 2rem 0;
  }

  .Professionals_Read-more a:hover {

    background-color: red;
    transition: all ease-in-out .3s;
    padding: 15px 60px;
    border-radius: 20px;
  }
}

@media screen and (min-width: 768px) {

  .Professionals_box-services {

    max-width: 600px;
    margin: 0 auto;
  }

  .Professionals_services-boxes {

    display: flex;
    justify-content: center;
  }

  .Professionals_services {

    width: 1470px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .Professionals_box-content-infos {

    width: 47%;
  }

  .Professionals_box-services h1 {

    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .Professionals_description {

    font-size: 1.1rem;
  }

  .Professionals_box-content-infos {

    max-width: 369px;
  }
}