@media screen and (min-width: 1000px) {
 
  .Ads-contact {

    width: 100%;
    height: 100%;

    padding: 6rem 0 1rem 0;
    box-sizing: border-box;

    z-index: -100;
  }

  .Ads_cards-contact {

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .Ads_card-content-Contact {

    width: 100%;
    height: 350px;

    position: relative;

    max-width: 260px;
  }

  .Ads_card-box {

    background-color: rgb(235, 232, 232);

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }

  .Ads_card-icon {

    position: absolute;
    top: 5px;
    right: 5px;

    display: flex;
    
  }

  .Ads_card-icon img{

    width: 35px;
  }

  .Ads_card-box p {

    margin: 0;
  }
}