@media screen and (min-width: 358px) {

  .FormLogin {


    width: 100%;
    height: 100vh;

    background-image: url("../../../public/banner/banner__02.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .FormLogin_content {

    background-color: #00000050;

    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .FormLogin_box {

    background-color: #00000080;

    width: 320px;
    height: 450px;

    overflow: hidden;
  }

  /*Login----------------*/
  .FormLogin_box-content {

    width: 100%;

    padding: .70rem;
    box-sizing: border-box;

    transform: translateY(-410px);
    transition: all ease-in-out 2s;
  }

  .FormLogin_box-contentJS {

    width: 100%;

    padding: .70rem;
    box-sizing: border-box;

    transform: translateY(0px);
    transition: all ease-in-out 2s;
  }

  /*Register-----------*/
  .FormLogin_box-contentRegister {

    width: 100%;

    padding: .70rem;
    box-sizing: border-box;

    transform: translateY(-410px);
    transition: all ease-in-out 2s;
  }

  .FormLogin_box-contentRegisterJS {

    width: 100%;

    padding: .70rem;
    box-sizing: border-box;

    transform: translateY(50px);
    transition: all ease-in-out 2s;
  }


  .FormLogin_box-title {

    display: flex;
    flex-direction: column;
  }

  .FormLogin_box-title h1 {

    color: white;

    font-family: "Anta", sans-serif;
    font-size: 1.7rem;
    text-transform: uppercase;

    margin: 1rem 0 .50rem 0;
  }

  .FormLogin_box-line {

    background-color: hsl(240, 83%, 80%);
    width: 100px;
    height: 4px;

    margin: 0 0 .50rem 0;
  }

  .FormLogin_form {

    width: 100%;

    display: flex;
    flex-direction: column;
  }

  .FormLogin_form input {

    background: transparent;
    color: rgb(243, 240, 240);

    height: 40px;
    border: none;
    outline: none;
    border-bottom: 1px solid white;

    margin: 2.2rem 0 0 0;

    font-family: sans-serif;
    font-size: 1.3rem;
  }

  .formRegister input {

    margin: 1.3rem 0 0 0;
  }

  .FormLogin_form input::placeholder {

    color: white;

    font-size: 1.1rem;
  }

  .FormLogin_box-submit input {

    background-color: blueviolet;
    color: white;

    width: 50%;

    font-weight: bold;
    font-family: "Anta", sans-serif;
    letter-spacing: 2px;
    font-size: 1.1rem;
    border: none;
    transition: all ease-in-out .3s;
  }

  .FormLogin_box-submit input:hover {

    background-color: blueviolet;

    transition: all ease-in-out .3s;
    width: 55%;
    cursor: pointer;
    margin-left: 10px;
  }

  .FormLogin_box-redirect {

    margin-top: 2rem;
  }

  .FormLogin_box-redirect p {

    color: white;

    font-size: 1.1rem;
  }

  .FormLogin_box-redirect a {

    color: orange;

    font-size: 1.2rem;
    text-decoration: none;
  }
}

@media screen and (min-width: 550px) {

  .FormLogin_box {

    width: 450px;
    height: 450px;
  }

  .FormLogin_box-errorViews {

    width: 450px;

    display: flex;
    justify-content: center;
  }
}