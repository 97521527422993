@media screen and (min-width: 358px) {

  .Comments_general {

    width: 100%;
  }

  .Comments {

    padding: 3rem .50rem 0rem .50rem;
  }

  .Comments_infoCounter {

    display: flex;
    flex-direction: column;

  }

  .Comments_infoCounter h1 {

    color: black;
    font-size: 1.1rem;
    margin-bottom: 0.20rem;
  }

  .Comments_Line {

    background-color: rgb(153, 209, 41);

    width: 30%;
    height: 3px;
  }

  .Comments_boxesComments {

    width: 100%;

    display: flex;
    justify-content: space-between;

    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(160, 155, 155);
  }

  .Comments_boxImage {

    width: 18%;
  }

  .Comments_boxImage img {

    width: 100%;

    border-radius: 50%;
    border: 1px solid black;
  }

  .Comments_CommmetUser {

    width: 77%;

    box-sizing: border-box;
  }

  .Comments_CommmetUser h1 {

    color: rgb(119, 43, 16);

    font-size: 1.2rem;
    margin: 0;
  }

  .Comments_Commmet-box-content {

    width: 100%;
    height: auto;

    padding: .50rem;
    line-height: normal;
    text-align: start;
    box-sizing: border-box;
    overflow: hidden;
  }

  .Comments_CommmetUser p {

    color: black;

    font-size: 1rem;
    margin: 1rem 0 0 0;
    line-height: normal;

  }

  .Commetns_form {

    padding: 0rem 0rem .90rem 0rem;
  }

  .Commetns_box-content {

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Commetns_form-inpus {

    width: 100%;
  }

  .Commetns_form-inpus textarea {

    background-color: rgb(244, 238, 238);

    width: 100%;
    height: 100px;

    border-radius: 10px;
    border: none;
    padding: .30rem;
    box-sizing: border-box;
    font-size: 1.1rem;
    font-family: sans-serif;
    padding: .50rem;
    box-sizing: border-box;
  }

  .Commetns_form-inpus textarea::placeholder {

    padding: .50rem;
    font-size: 1rem;
    font-family: sans-serif;
  }

  .Commetns_form-boxcontent-input {

    padding-top: 1rem;
  }

  .Commetns_form-boxcontent-input input {

    background-color: rgb(210, 146, 27);
    color: white;

    width: 50%;
    height: 40px;

    font-size: 1rem;
    font-weight: bold;
    font-family: sans-serif;

    border-radius: 10px;
    border: none;
    transition: all ease-in-out .3s;
  }

  .Commetns_form-boxcontent-input input:hover {

    background-color: rgb(174, 122, 24);
    transition: all ease-in-out .3s;
    cursor: pointer;
  }

  .Commetns_form_auth-content {

    background-color: #FF000080;

    width: 100%;
    display: flex;
    border-radius: 20px;
    margin-top: 1rem;
  }

  .Commetns_form-auth {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: .50rem;
    
  }

  .Commetns_form-auth p{

    color: white;

    font-size: 1rem;
    font-weight: bold;

    margin: 0;
  }
}

@media screen and (min-width: 500px) {

  .Comments {

    display: flex;
    justify-content: center;
  }

  .Comments_generalComplete {

    max-width: 500px;
  }

  .Comments_boxImage {

    width: 12%;
  }

  .Comments_CommmetUser {

    width: 83%;

    box-sizing: border-box;
  }
}

@media screen and (min-width: 768px) {

  .Comments_general {

    width: 50%;
    margin-top: 4rem;
    margin-bottom: 8rem;
  }

  .Comments {

    padding: 4rem 0 0 0;
  }

  .Comments_Line {

    background-color: rgb(153, 209, 41);

    width: 200px;
    height: 3px;
  }

  .Comments_boxesComments {

    width: 350px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(160, 155, 155);
  }

  .Comments_boxImage {

    width: 18%;
  }

  .Comments_boxImage img {

    width: 100%;

    border-radius: 50%;
    border: 1px solid black;
  }

  .Comments_CommmetUser {

    width: 77%;

    box-sizing: border-box;
  }

  /**/

  .Commetns_box-content {

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Commetns_form-inpus {

    width: 100%;
  }

  .Commetns_form-inpus textarea {

    background-color: rgb(244, 238, 238);

    width: 100%;
    height: 100px;

    border-radius: 10px;
    border: none;
    padding: .30rem;
    box-sizing: border-box;
    font-size: 1.1rem;
  }

  .Commetns_form-inpus textarea::placeholder {

    padding: .50rem;
    font-size: 1rem;
    font-family: sans-serif;
  }

  .Commetns_form-boxcontent-input {

    padding-top: 1rem;
  }

  .Commetns_form-boxcontent-input input {

    background-color: rgb(210, 146, 27);
    color: white;

    width: 50%;
    height: 40px;

    font-size: 1rem;
    font-weight: bold;
    font-family: sans-serif;

    border-radius: 10px;
    border: none;
    transition: all ease-in-out .3s;
  }

  .Commetns_form-boxcontent-input input:hover {

    background-color: rgb(174, 122, 24);
    transition: all ease-in-out .3s;
    cursor: pointer;
  }
}

@media screen and (min-width: 830px) {

  .Comments_boxesComments {

    width: 390px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(160, 155, 155);
  }
}

@media screen and (min-width: 1200px) {

  .Comments_general {

    width: 50%;
    margin-top: 4rem;
    margin-bottom: 8rem;
  }

  .Comments_boxImage {

    width: 10%;
  }

  .Comments_boxImage img {

    width: 100%;

    border-radius: 50%;
    border: 1px solid black;


  }

  .Comments_CommmetUser {

    width: 80%;

    box-sizing: border-box;
    margin-left: 20px;
  }

  .Comments_boxesComments {

    width: 100%;

    display: flex;
    justify-content: start;

    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(160, 155, 155);
  }


  .Comments {

    width: 100%;
    padding: 4rem 0 0 0;
  }
}