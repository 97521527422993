@media screen and (min-width: 358px) {
  .Navbar {

    background-color: white;

    width: 100%;

    position: fixed;
    top: 0;
    z-index: 2000;

    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }

  .Navbar_content {

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Navbar_link-compani {

    text-decoration: none;
  }

  .Navbar_content-company-info {

    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
  }

  .Navbar_logo {

    width: 80px;
    height: 80px;

    background-image: url("../../../public/logos/logo_00.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    border-radius: 50%;
  }

  .Navbar_company {

    margin-top: 10px;
  }

  .Navbar_company p {

    font-family: "Anta", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.1rem;

    margin: 0;
    padding: 0;
  }

  .Navbar_content-Menu {

    width: 80px;
    height: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Navbar_content-Line {

    width: 30px;
    height: 4px;

    border-top: 3px solid black;

    display: flex;

    transition: .6s;
    display: block;

    position: relative;
  }

  .Navbar_content-Line::after,
  .Navbar_content-Line::before {

    content: "";
    background-color: black;

    display: block;
    width: 100%;
    height: 3px;

    margin-top: 5px;
    transition: .3s;
    position: relative;
  }

  .Navbar_content-Line.active {

    border-top-color: transparent;
    transition: .3s;
  }

  .Navbar_content-Line.active::before {

    transform: rotate(-45deg);
    transition: .3s;
  }

  .Navbar_content-Line.active::after {

    transform: rotate(45deg);
    transition: .3s;
    top: -8px;
  }

  .Navbar_content-menuDesk {
    display: none;
  }
}

@media screen and (min-width: 768px) {

  .Navber_boxMenu {

    display: none;
  }

  .Navbar_content-menuDesk {
    display: block;
  }
}

@media screen and (min-width: 800px) {

  .Navbar_content {

    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}