@media screen and (min-width: 358px) {
  
  .loading {

    width: 100vw;
    height: 100vh;
  }

  .loading_box {

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading_box-content {

    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading_box-content img{

    width: 50%;
    max-width: 300px;
  }

  .loading_box-gif {

    display: flex;
    justify-content: center;
    align-items: center;
  }
}