@media screen and (min-width: 358px) {

  .InfoServices_content {
    padding: 1rem;
  }

  .InfoServices_content h1 {

    color: rgb(86, 81, 81);

    font-size: 1.2rem;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 3px;
    font-weight: bold;

    margin: 2rem 0 0 0;
  }

  .InfoServices_content p {

    color: rgb(198, 135, 18);

    font-size: 1.3rem;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    word-spacing: 5px;

    margin: 1rem 0 0 0;
  }

  .InfoServices_content p strong {

    color: rgb(205, 144, 30);

    font-size: 1.4rem;
    text-transform: uppercase;
  }

  .InfoServices_boxContent-link {

    margin-top: 2rem;

    display: flex;

    align-items: center;
  }

  .InfoServices_Link-contact {

    background-color: #bc1818;

    color: white;

    font-size: 1.1rem;
    font-family: sans-serif;
    font-weight: bold;

    border-radius: 5px 20px 20px 5px;

    text-decoration: none;
    padding: 10px 50px;
    transition: .9s all ease-in-out;
  }

  .InfoServices_Link-contact:hover {

    background-color: #911b1b;
    padding: 10px 80px;
    transition: .9s all ease-in-out;
  }
}

@media screen and (min-width: 850px) {

  .InfoServices {

    display: flex;
    justify-content: center;
  }

  .InfoServices_boxContent-link {

    display: flex;
    justify-content: center;
  }

  .InfoServices_Link-contact {

    background-color: #bc1818;

    color: white;

    font-size: 1.1rem;
    font-family: sans-serif;
    font-weight: bold;

    border-radius: 20px 20px 20px 20px;

    text-decoration: none;
    padding: 10px 50px;
    transition: .9s all ease-in-out;
  }

  .InfoServices_Link-contact:hover {

    background-color: #911b1b;
    padding: 13px 70px;
    transition: .9s all ease-in-out;
  }
}

@media screen and (min-width: 1200px) {

  .InfoServices_content {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1990px) {

  .InfoServices_content {
    margin-top: 6rem;
  }
}