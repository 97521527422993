@media screen and (min-width: 358px) {

  .ArticleInfo {

    width: 100%;
    margin-top: 5rem;
  }

  /*
    Style Carrossel
  */

  .ArticleInfo_box-carrossel {

    width: 100%;
    overflow: hidden;

    position: relative;

  }

  .Article_content-boxes {

    display: flex;


    transition: 0.9s ease-in-out all;
    transform: translateX(0);
  }

  .Article_content-boxesTest {

    display: flex;
  }

  .Article_content-arrow {

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: .50rem;
    box-sizing: border-box;

    z-index: 100;
  }

  .Article_content-arrowRight {

    background-color: #00000060;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .Article_content-arrowRight:nth-child(1) {

    color: white;

    font-size: 1.5rem;
  }

  .Article_content-arrowLeft {

    background-color: #00000060;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .Article_content-arrowLeft:nth-child(2) {

    color: white;

    font-size: 1.5rem;
  }

  .Article_content-box_image-carrossel {

    width: 100vw;
    height: 400px;
  }

  /*
    Style Carrossel FIM
  */

  .ArticleInfo_boxTXT {

    max-width: 600px;
    padding: .70rem;
  }

  .ArticleInfo_contenticons-like {

    display: flex;
    justify-content: space-between;
  }

  .ArticleInfo_counter {

    display: flex;
    align-items: center;
  }

  .ArticleInfo_counter p {

    margin-left: 10px;
  }

  .ArticleInfo_iconsBOX {

    background-color: rgb(244, 244, 244);

    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
  }

  .ArticleInfo_icons {

    font-size: 1.4rem;
  }

  .FullLike {

    color: blue;
  }

  .ArticleInfo-iconLike:hover {

    color: blue;

    cursor: pointer;
    font-size: 1.2rem;
    transition: all ease-in-out .3s;
  }

  .ArticleInfo-iconLikeBad:hover {

    color: red;

    cursor: pointer;
    font-size: 1.2rem;
    transition: all ease-in-out .3s;
  }

  .ArticleInfo_contenticons-likeAuth {

    background-color: #FF000080;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: .30rem;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 1rem 0 1rem 0;
  }

  .ArticleInfo_contenticons-likeAuth-box-incon:nth-child(1) {

    color: rgb(204, 255, 0);


    font-size: 1.5rem;
  }

  .ArticleInfo_contenticons-likeAuth-box-TXT p {

    color: white;

    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
}

@media screen and (min-width: 380px) {


  .ArticleInfo_contenticons-likeAuth {

    background-color: #FF000080;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: .30rem;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 1rem 0 1rem 0;
    width: 90%;
  }

  .ArticleInfo_contenticons-likeAuth-box-incon:nth-child(1) {

    color: rgb(204, 255, 0);

    margin-right: 10px;
    font-size: 1.5rem;
  }

  .ArticleInfo_contenticons-likeAuth-box-TXT p {

    color: white;

    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
}


@media screen and (min-width: 530px) {


  .Article_content-box_image-carrossel {

    width: 100vw;
    height: 450px;
  }
}

@media screen and (min-width: 590px) {

  .Article_content-box_image-carrossel {

    width: 100vw;
    height: 450px;
  }
}

@media screen and (min-width: 600px) {

  .ArticleInfo_contenticons-like {

    width: 90%;
  }

  .Article_content-box_image-carrossel {

    width: 100vw;
    height: 520px;
  }
}

@media screen and (min-width: 670px) {

  .Article_content-box_image-carrossel {

    width: 100vw;
    height: 600px;
  }
}

@media screen and (min-width: 768px) {

  .ArticleInfo {

    width: 45%;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .Article_content-boxesTest {

    display: flex;
    width: 100%;
  }

  .Article_content-box_image-carrossel {

    width: 100%;
    height: auto;
  }

  .ArticleInfo_boxTXT {

    max-width: 600px;
    padding: 0;
  }
}

@media screen and (min-width: 840px) {

  .ArticleInfo {

    width: 330px;
  }

  .ArticleInfo_contenticons-likeAuth-box-incon:nth-child(1) {

    color: rgb(204, 255, 0);

    margin-right: 0px;
    margin-left: 5px;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {

  .ArticleInfo {

    width: 50%;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .ArticleInfo {

    width: 34%;
  }
}


@media screen and (min-width: 1201px) {

  .ArticleInfo {

    width: 50%;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .ArticleInfo {

    max-width: 400px;
    max-height: 1400px;
  }
}