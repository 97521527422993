@media screen and (min-width: 358px) {
  
  .Article_Media {

    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) {
  
  .Article_components {

    width: 100%;
    display: flex; 
    justify-content: space-around;
  }
}

@media screen and (min-width: 1200px) {

  .Article_boxes {

    display: flex; 
    justify-content: center;
  }
  
  .Article_components {

    max-width: 1200px;
    display: flex; 
    justify-content: center;
  }
}